import {
  Button,
  ButtonV2,
  Calendar as CalendarWidget,
  CalendarEventList as CalendarEventListWidget,
  DropDownItemV2,
  DropdownOption,
  DropdownV2,
  GlobalStyles,
  Icon,
  StackV2,
  Text,
  View,
} from '@andromeda'
import Box from '@mui/material/Box'
import { Types } from '@orbit'
import { useAppDispatch } from '@redux/hooks'
import { listWidgetData } from '@redux/reducers/dashboardReducer'
import { NavigationContext } from '@utils/context/Navigation.context'
import { JOB_AGREEMENT_STATUS } from '@utils/enum/status/job_agreement'
import { capitalize } from '@utils/functions/helperFunctions'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import useDebouncedCallback from '@utils/hooks/useDebouncedCallback'
import { iWidget, iWidgetCalendar, WidgetEnums } from '@utils/types/dasboard'
import moment from 'moment'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Navigate, ToolbarProps } from 'react-big-calendar'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { WidgetHeader } from './widget-header'
import { Typography, useMediaQuery } from '@mui/material'
import { breakpoints } from 'src/andromeda/helpers/theme'
const STATUS_OPTIONS = [
  {
    eid: '',
    value: 'All',
  },
  {
    eid: JOB_AGREEMENT_STATUS.ASSIGNED,
    value: 'Assigned',
  },
  {
    eid: 'LIVE',
    value: 'Booked',
  },
  {
    eid: JOB_AGREEMENT_STATUS.REMOVED,
    value: 'Removed',
  },
  {
    eid: JOB_AGREEMENT_STATUS.DONE,
    value: 'Done',
  },
  {
    eid: JOB_AGREEMENT_STATUS.COMPLETED,
    value: 'Completed',
  },
]

type CalendarProps = {
  calendarData?: iWidgetCalendar
  loading?: boolean
  widgets?: Array<iWidget>
  meta?: iWidget
  role?: Types.Roles.iRole
}

const Calendar = ({ calendarData: calendarDataProp, loading, widgets, meta, role }: CalendarProps) => {
  const router = useRouter()
  const { screenSize } = useScreenDimensions()
  const dispatch = useAppDispatch() // For dispatching Actions

  const { goToRequests } = useContext(NavigationContext)

  const [calendarView, setCalendarView] = useState<'month' | 'list'>('month')
  const [deliverableStatus, setDeliverableStatus] = useState<DropdownOption>(STATUS_OPTIONS[0])
  const [date, setDate] = useState<Date>(new Date())
  const [calendarData, setCalendarData] = useState<iWidgetCalendar | undefined>(calendarDataProp)
  const [page, setPage] = useState('1')

  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.sm}px)`)

  useEffect(() => {
    setCalendarView(isMobile ? 'list' : 'month')
  }, [isMobile])

  useEffect(() => {
    if (role && meta) {
      dispatch(
        listWidgetData({
          role: role.eid,
          widget: meta.eid,
          year: moment(date).get('year').toString(),
          month: (moment(date).get('month') + 1).toString(),
          view: calendarView,
          deliverable_statuses: deliverableStatus.eid,
          page,
        })
      ).then((res) => {
        setCalendarData((prev) => [...(prev ? prev : []), ...res.payload.data])
      })
    }
    // for lazy loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const listWidgetDataFn = useCallback(() => {
    if (role && meta) {
      dispatch(
        listWidgetData({
          role: role.eid,
          widget: meta.eid,
          year: moment(date).get('year').toString(),
          month: (moment(date).get('month') + 1).toString(),
          view: calendarView,
          deliverable_statuses: deliverableStatus.eid,
        })
      ).then((res) => {
        setCalendarData(res.payload.data)
      })
    }
  }, [calendarView, date, deliverableStatus, dispatch, meta, role])
  const debouncedListWidgetData = useDebouncedCallback(listWidgetDataFn, 500)

  useEffect(() => {
    debouncedListWidgetData()
  }, [debouncedListWidgetData])

  useEffect(() => {
    setCalendarData(calendarDataProp)
  }, [calendarDataProp])

  const _renderHeaderButtons = (toolbarProps?: ToolbarProps) => {
    return (
      <StackV2 direction="row" minWidth={{ xxs: '100%', sm: 'auto' }} alignItems="center" marginBottom="10px">
        {calendarView === 'month' && (
          <StackV2 direction={'row'} alignItems={'center'}>
            <Button
              text={'<'}
              transparent
              type={'outlined'}
              style={{ borderWidth: 0 }}
              onPress={() => {
                toolbarProps?.onNavigate(Navigate.PREVIOUS)
              }}
            />
            <Text size={'base'} bold>
              {toolbarProps?.label}
            </Text>
            <Button
              text={'>'}
              transparent
              type={'outlined'}
              style={{ borderWidth: 0 }}
              onPress={() => {
                toolbarProps?.onNavigate(Navigate.NEXT)
              }}
            />
          </StackV2>
        )}
        <StackV2
          direction={'row'}
          width={'auto'}
          height={'auto'}
          spacing={2}
          justifyContent={calendarView === 'list' ? 'space-between' : ''}>
          <DropdownV2
            title={capitalize(calendarView)}
            menuIcon={<Icon.ChevronDown color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />}>
            <DropDownItemV2 key={'month'} onClick={() => setCalendarView('month')}>
              Month View
            </DropDownItemV2>
            <DropDownItemV2 key={'list'} onClick={() => setCalendarView('list')}>
              List View
            </DropDownItemV2>
          </DropdownV2>
          <ButtonV2
            variant={'outlined'}
            onClick={() => {
              goToRequests()
            }}>
            <Typography sx={{ display: { xxs: calendarView === 'month' ? 'none' : 'flex', sm: 'flex' } }}>View All Jobs</Typography>
            <Icon.ChevronRight color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />
          </ButtonV2>
        </StackV2>
      </StackV2>
    )
  }

  const _renderFilter = (toolbarProps?: ToolbarProps) => {
    return (
      <StackV2 direction={'column'} marginBottom="10px" paddingX="12px">
        <StackV2 direction={'row'} justifyContent={'space-between'}>
          <StackV2 direction={'row'} spacing={2} alignItems={'center'}>
            <Text size={'sm'} bold>
              {'Filter by:'}
            </Text>
            <DropdownV2
              title={capitalize(deliverableStatus.value)}
              menuIcon={<Icon.ChevronDown color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />}>
              {STATUS_OPTIONS.map((status) => (
                <DropDownItemV2 key={status.value} onClick={() => setDeliverableStatus(status)}>
                  {status.value}
                </DropDownItemV2>
              ))}
            </DropdownV2>
          </StackV2>
        </StackV2>
        {/* {calendarView === 'month' && (
                    <StackV2 width={'auto'} direction={'row'} flex={1} justifyContent={'center'} alignItems={'center'}>
                        <Button
                            text={'<'}
                            transparent
                            type={'outlined'}
                            style={{ borderWidth: 0 }}
                            onPress={() => toolbarProps?.onNavigate(Navigate.PREVIOUS)}
                        />
                        <Text size={'base'} bold>
                            {toolbarProps?.label}
                        </Text>
                        <Button
                            text={'>'}
                            transparent
                            type={'outlined'}
                            style={{ borderWidth: 0 }}
                            onPress={() => toolbarProps?.onNavigate(Navigate.NEXT)}
                        />
                    </StackV2>
                )} */}
      </StackV2>
    )
  }

  const _calendarToolbar = (toolbarProps?: ToolbarProps) => {
    return (
      <WidgetHeader
        IconComponent={(props) => <Icon.Calendar {...props} />}
        title="Calendar"
        headerButtons={_renderHeaderButtons(toolbarProps)}
        filter={_renderFilter(toolbarProps)}
      />
    )
  }

  if (loading) {
    return (
      <View style={{ flex: 1, width: '100%' }}>
        <View style={{ flex: 1 }}>
          <SkeletonTheme height={30} baseColor={GlobalStyles.SLATE_100} highlightColor={GlobalStyles.SLATE_200}>
            <Skeleton style={{ width: 100 }} count={1} />
            <Skeleton count={6} />
          </SkeletonTheme>
        </View>
      </View>
    )
  }

  if (!calendarData || (widgets && !widgets.some((widget) => widget.type === WidgetEnums.CALENDAR))) return null

  return (
    <StackV2 direction={'column'}>
      <StackV2 direction={'column'}>
        {calendarView === 'month' ? (
          <Box width={'auto'}>
            <CalendarWidget
              onChangeMonth={(month) => {
                setDate(month)
              }}
              events={calendarData?.map((val) => {
                return { ...val, title: `${val.name} - ${val.description}` }
              })}
              customToolbar={_calendarToolbar}
              style={{ height: 700 }}
              onSelectEvent={(event: any) => {
                router.push(`${router.asPath}/jobs/${(event as unknown as { job_eid: string }).job_eid}`)
              }}
            />
          </Box>
        ) : (
          <>
            {_calendarToolbar()}
            <View>
              <CalendarEventListWidget
                onPressDeliverable={(job_eid) => {
                  if (job_eid) {
                    router.push(`${router.asPath}/jobs/${job_eid}`)
                  }
                }}
                data={calendarData as unknown as Array<Types.Deliverable.iDeliverable>}
                lazyLoad={() => {
                  setPage((prev) => (parseInt(prev) + 1).toString())
                }}
              />
            </View>
          </>
        )}
      </StackV2>
    </StackV2>
  )
}

export { Calendar }
