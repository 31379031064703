import { ButtonV2, GlobalStyles, Icon, JobOverview, StackV2, View } from '@andromeda'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { NavigationContext } from '@utils/context/Navigation.context'
import { WidgetEnums, iWidget, iWidgetJobComplete, iWidgetJobJobs, iWidgetJobPipeline } from '@utils/types/dasboard'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { WidgetHeader } from './widget-header'

const JobsOverview = ({
  pipelineLoading,
  jobsLoading,
  completedLoading,
  widgets,
  pipelineData,
  jobsData,
  completedData,
}: {
  pipelineLoading?: boolean
  jobsLoading?: boolean
  completedLoading?: boolean
  widgets?: Array<iWidget>
  pipelineData?: iWidgetJobPipeline
  jobsData?: iWidgetJobJobs
  completedData?: iWidgetJobComplete
}) => {
  let showPipeline = false
  let showJobs = false
  let showComplete = false
  const router = useRouter()
  const { goToRequests, workspace } = useContext(NavigationContext)

  const SkeletonLoader = () => {
    return (
      <View style={{ flex: 1 }}>
        <SkeletonTheme height={30} baseColor={GlobalStyles.SLATE_100} highlightColor={GlobalStyles.SLATE_200}>
          <Skeleton count={0.5} />
          <Skeleton count={0.5} />
          <Skeleton count={3.5} />
          <Skeleton count={3} />
          <Skeleton count={0.5} />
          <Skeleton count={0.5} />
        </SkeletonTheme>
      </View>
    )
  }

  if (widgets) {
    /**
     * check if user tabs section is available, if not return null
     */
    if (widgets && !widgets.some((widget) => widget.type === WidgetEnums.JOBS_OVERVIEW)) return null
    /**
     * If user tabs is available check for each tab
     */
    showPipeline = widgets && !widgets.some((widget) => widget.type === WidgetEnums.JOBS_OVERVIEW_PIPELINE) ? false : true
    showJobs = widgets && !widgets.some((widget) => widget.type === WidgetEnums.JOBS_OVERVIEW_JOBS) ? false : true
    showComplete = widgets && !widgets.some((widget) => widget.type === WidgetEnums.JOBS_OVERVIEW_COMPLETE) ? false : true
    // if (!showPipeline && !showJobs && !showComplete) return null
  }

  const _renderPipelineWidget = () => {
    if (!showPipeline || !pipelineData) return null
    return (
      <JobOverview
        stat={{
          label: 'Pipeline',
          count: pipelineData.pipeline_count,
          icon: <Icon.Activity />,
          info: 'The number of jobs sitting within leads or drafts ',
          onPress: () => {
            trackEvent({
              event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
              eventProperties: {
                page_name: 'dashboard',
                section_name: 'Jobs Overview',
                component_name: 'Pipeline',
                workspace: workspace,
              },
            })
            goToRequests({ status: 'DRAFT', page: 1, per_page: 20 })
          },
        }}
        opp={[
          {
            category: 'Jobs',
            count: pipelineData.external_leads_count,
            label: 'External Leads',
            info: 'Total number of jobs sitting within leads ',
            onPress: () => {
              trackEvent({
                event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
                eventProperties: {
                  page_name: 'dashboard',
                  section_name: 'Pipeline',
                  component_name: 'External Leads',
                  workspace: workspace,
                },
              })
              goToRequests({ status: 'DRAFT', page: 1, per_page: 20 })
            },
          },
          {
            category: 'Jobs',
            count: pipelineData.drafts_count,
            label: 'Drafts',
            info: 'Total number of jobs sitting within drafts ',
            onPress: () => {
              trackEvent({
                event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
                eventProperties: {
                  page_name: 'dashboard',
                  section_name: 'Pipeline',
                  component_name: 'Drafts',
                  workspace: workspace,
                },
              })
              goToRequests({ status: 'DRAFT', page: 1, per_page: 20 })
            },
          },
        ]}
        total={{
          amount: pipelineData.pipeline_value,
          currency: pipelineData.pipeline_value_currency,
          label: 'Total Pipeline Value',
          info: 'Total monetary value of all jobs across leads and drafts',
        }}
      />
    )
  }

  const _renderJobsWidget = () => {
    if (!showJobs || !jobsData) return null
    return (
      <JobOverview
        stat={{
          label: 'Jobs',
          count: jobsData.job_count,
          icon: <Icon.Layers />,
          info: 'Total published jobs ',
          onPress: () => {
            trackEvent({
              event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
              eventProperties: {
                page_name: 'dashboard',
                section_name: 'Jobs Overview',
                component_name: 'All Jobs',
                workspace: workspace,
              },
            })
            goToRequests({ status: 'LIVE', page: 1, per_page: 20 })
          },
        }}
        opp={[
          {
            category: 'Deliverables',
            count: jobsData.assigned_deliverables,
            label: 'Assigned',
            info: 'Total deliverables that have been assigned to talent across published jobs',
            onPress: () => {
              trackEvent({
                event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
                eventProperties: {
                  page_name: 'dashboard',
                  section_name: 'Jobs',
                  component_name: 'Jobs Assigned',
                  workspace: workspace,
                },
              })
              goToRequests({ deliverable_agreements_statuses: 'ASSIGNED', page: 1, per_page: 20 })
            },
          },
          {
            category: 'Deliverables',
            count: jobsData.booked_deliverables,
            label: 'Booked',
            info: 'Total deliverables that have been booked to talent across published jobs',
            onPress: () => {
              trackEvent({
                event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
                eventProperties: {
                  page_name: 'dashboard',
                  section_name: 'Jobs',
                  component_name: 'Jobs Booked',
                  workspace: workspace,
                },
              })
              goToRequests({ deliverable_agreements_statuses: 'BOOKED', page: 1, per_page: 20 })
            },
          },
        ]}
        total={{
          amount: jobsData.job_value,
          currency: jobsData.job_value_currency,
          label: 'Total Jobs Value',
          info: 'Total talent fees assigned or booked across published jobs ',
        }}
      />
    )
  }

  const _renderCompletedWidget = () => {
    if (!showComplete || !completedData) return null
    return (
      <JobOverview
        stat={{
          label: 'Complete',
          count: completedData.completed_jobs_count,
          icon: <Icon.CheckSquare />,
          info: 'Total complete jobs',
          onPress: () => {
            trackEvent({
              event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
              eventProperties: {
                page_name: 'dashboard',
                section_name: 'Jobs Overview',
                component_name: 'All Completed Jobs',
                workspace: workspace,
              },
            })
            goToRequests({ status: 'COMPLETED', page: 1, per_page: 20 })
          },
        }}
        opp={[
          {
            category: 'Deliverables',
            count: completedData.completed_deliverables_count,
            label: 'Completed',
            info: 'Total deliverables within complete jobs ',
            onPress: () => {
              trackEvent({
                event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
                eventProperties: {
                  page_name: 'dashboard',
                  section_name: 'Complete',
                  component_name: 'Deliverables Completed',
                  workspace: workspace,
                },
              })
              goToRequests({ deliverable_statuses: 'COMPLETED', page: 1, per_page: 20 })
            },
          },
        ]}
        total={{
          amount: completedData.completed_jobs_value,
          currency: completedData.job_value_currency,
          label: 'Total Completed Job Fees',
          info: 'The total monetary value across all complete jobs',
        }}
      />
    )
  }

  const _onViewAllJobsClicked = () => {
    trackEvent({
      event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
      eventProperties: { page_name: 'dashboard', section_name: 'Jobs Overview', component_name: 'View All Jobs Button' },
      workspace: workspace,
    })
    goToRequests()
  }

  return (
    <StackV2 flex={1} direction={'column'}>
      <WidgetHeader
        loading={pipelineLoading && jobsLoading && completedLoading}
        title="Jobs Overview"
        headerButtons={
          <StackV2>
            <ButtonV2
              variant={'outlined'}
              onClick={() => {
                trackEvent({
                  event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
                  eventProperties: {
                    page_name: 'dashboard',
                    section_name: 'Jobs Progress',
                    component_name: 'View All Jobs Button',
                    workspace: workspace,
                  },
                })
                goToRequests()
              }}
              endIcon={<Icon.ChevronRight color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />}>
              View All Jobs
            </ButtonV2>
          </StackV2>
        }
      />
      <StackV2 direction={{ md: 'column', lg: 'row', xl: 'row', xs: 'column' }} spacing={2} flexWrap={'wrap'}>
        {pipelineLoading ? (
          <View style={{ flex: 1, width: '100%' }}>
            <SkeletonLoader />
          </View>
        ) : (
          _renderPipelineWidget()
        )}
        {jobsLoading ? (
          <View style={{ flex: 1, width: '100%' }}>
            <SkeletonLoader />
          </View>
        ) : (
          _renderJobsWidget()
        )}
        {completedLoading ? (
          <View style={{ flex: 1, width: '100%' }}>
            <SkeletonLoader />
          </View>
        ) : (
          _renderCompletedWidget()
        )}
      </StackV2>
    </StackV2>
  )
}

export { JobsOverview }
