import { DeliverableOverview as DeliverableOverviewWidget, FILTER_OPTIONS, GlobalStyles, STATUS_OPTIONS, StackV2, View } from '@andromeda'
import { Types } from '@orbit'
import { useAppDispatch } from '@redux/hooks'
import { listWidgetData } from '@redux/reducers/dashboardReducer'
import { WidgetEnums, iWidget, iWidgetDeliverableOverview } from '@utils/types/dasboard'
import moment from 'moment'
import { useEffect, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { WidgetHeader } from './widget-header'

const DeliverableOverview = ({
  loading = false,
  deliverableOverviewData: deliverableOverviewDataProp,
  widgets,
  meta,
  role,
}: {
  meta?: iWidget
  role?: Types.Roles.iRole
  loading?: boolean
  deliverableOverviewData?: iWidgetDeliverableOverview
  widgets?: Array<iWidget>
}) => {
  const dispatch = useAppDispatch() // For dispatching Actions
  const [deliverablesOverviewStatusFilter, setDeliverablesOverviewStatusFilter] = useState(STATUS_OPTIONS[0])
  const [deliverablesOverviewDateFilter, setDeliverablesOverviewDateFilter] = useState(FILTER_OPTIONS[FILTER_OPTIONS.length - 1])
  const [deliverableOverviewData, setDeliverableOverviewData] = useState<iWidgetDeliverableOverview | undefined>(
    deliverableOverviewDataProp
  )

  useEffect(() => {
    setDeliverableOverviewData(deliverableOverviewDataProp)
  }, [deliverableOverviewDataProp])

  const fetchDeliverableOverviewData = ({
    from_datetime = deliverablesOverviewDateFilter,
    deliverable_statuses = deliverablesOverviewStatusFilter,
  }) => {
    if (role && meta) {
      dispatch(
        listWidgetData({
          role: role.eid,
          widget: meta.eid,
          from_datetime: moment(new Date(Date.now())).subtract(from_datetime.value, 'M').format('YYYY-MM-DD'),
          deliverable_statuses: deliverable_statuses.value.toUpperCase(),
        })
      ).then((res) => {
        setDeliverableOverviewData(res.payload.data)
      })
    }
  }

  if (loading) {
    return (
      <View style={{ flex: 1, width: '100%' }}>
        <View style={{ flex: 1 }}>
          <SkeletonTheme height={30} baseColor={GlobalStyles.SLATE_100} highlightColor={GlobalStyles.SLATE_200}>
            <Skeleton style={{ width: 100 }} count={1} />
            <Skeleton count={6} />
          </SkeletonTheme>
        </View>
      </View>
    )
  }

  if (!deliverableOverviewData || (widgets && !widgets.some((widget) => widget.type === WidgetEnums.USER_PROFILE))) return null
  const dataset = [...deliverableOverviewData?.deliverables_per_type?.map((deliverable) => deliverable.count)]
  const labels = [...deliverableOverviewData?.deliverables_per_type?.map((deliverable) => deliverable.type)]

  return (
    <StackV2 flex={1}>
      <WidgetHeader title="Deliverable Overview" />
      <div className="flex-1">
        {deliverableOverviewData && (
          <DeliverableOverviewWidget
            statusFilter={deliverablesOverviewStatusFilter}
            dateFilter={deliverablesOverviewDateFilter}
            deliverableCount={deliverableOverviewData.deliverables_total_count}
            data={{
              labels: labels,
              datasets: [
                {
                  data: dataset,
                  backgroundColor: ['#7B61FF', '#0B845C', '#E70D32', '#FDCA61', '#3885FA'],
                  borderWidth: 1,
                },
              ],
            }}
            onChangeDateFilter={(e) => {
              fetchDeliverableOverviewData({ from_datetime: e })
              setDeliverablesOverviewDateFilter(e)
            }}
            onChangeStatusFilter={(e) => {
              fetchDeliverableOverviewData({ deliverable_statuses: e })
              setDeliverablesOverviewStatusFilter(e)
            }}
          />
        )}
      </div>
    </StackV2>
  )
}

export { DeliverableOverview }
