import { DropDownItemV2, DropdownV2, Avatar, Card, GlobalStyles, Icon, StackV2, Text, View, CardV2 } from '@andromeda'
import { Types } from '@orbit'
import { AppStyles } from '@styles'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { NavigationContext } from '@utils/context/Navigation.context'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { WidgetEnums, iWidget, iWidgetUserProfile } from '@utils/types/dasboard'
import { useContext, useEffect, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { FlexStyle } from 'react-native'

const UserProfileWidget = ({
  loading = false,
  widgets,
  profileData,
  initialRole,
  onChangeRole,
}: {
  profile: Types.User.iUser | null
  loading?: boolean
  widgets?: Array<iWidget>
  profileData?: iWidgetUserProfile
  initialRole?: Types.Roles.iRole
  onChangeRole?: (role: Types.Roles.iRole) => void
}) => {
  const { screenSize } = useScreenDimensions()
  const [selectedRole, setSelectedRole] = useState<Types.Roles.iRole | undefined>(undefined)
  const { workspace } = useContext(NavigationContext)

  useEffect(() => {
    setSelectedRole(initialRole)
  }, [initialRole])

  const _handleSelect = (role: Types.Roles.iRole) => {
    trackEvent({
      event: TrackingEventEnums.Dashboard.DASHBOARD_REFINE,
      eventProperties: {
        page_name: 'dashboard',
        section_name: 'User Profile',
        component_name: 'View by Role',
        from: selectedRole?.label,
        to: role.label,
        workspace: workspace,
      },
    })
    setSelectedRole(role)
    onChangeRole && onChangeRole(role)
  }

  const responsiveStyles = {
    responsiveAvatarSection: {
      direction: !screenSize.md ? 'column' : ('row' as FlexStyle['flexDirection']),
      justify: !screenSize.md ? 'center' : ('flex-start' as FlexStyle['justifyContent']),
      avatarStyle: {
        width: !screenSize.md ? 80 : 200,
        height: !screenSize.md ? 80 : 200,
        borderRadius: !screenSize.md ? 40 : 100,
      },
      avatarImageStyle: {
        width: !screenSize.md ? 80 : 200,
        height: !screenSize.md ? 80 : 200,
      },
      avatarPlaceholderStyle: {
        size: !screenSize.md ? AppStyles.AVATAR_SIZES.DASHBOARD : AppStyles.AVATAR_SIZES.PROFILE_PAGE,
      },
    },
    dropdownButton: {
      size: !screenSize.md ? 40 : 40,
    },
  }
  if (loading) {
    return (
      <StackV2 flex={1} width={'auto'} style={{ alignSelf: 'stretch' }}>
        <View style={{ flex: 1 }}>
          <SkeletonTheme baseColor={GlobalStyles.SLATE_100} highlightColor={GlobalStyles.SLATE_200}>
            <Skeleton style={{ height: 100, width: 100, borderRadius: 50 }} />
            <Skeleton count={9} />
          </SkeletonTheme>
        </View>
      </StackV2>
    )
  }
  if (widgets && !widgets.some((widget) => widget.type === WidgetEnums.USER_PROFILE)) return null

  return (
    <StackV2 spacing={2} alignItems={'center'}>
      <Text type={'heading'} style={{ color: GlobalStyles.MONO_BLACK, fontWeight: '500', paddingTop: 10 }}>
        Hello {profileData?.name},
      </Text>

      <Avatar
        style={{
          ...responsiveStyles.responsiveAvatarSection.avatarStyle,
          alignItems: 'center',
        }}
        imageStyle={responsiveStyles.responsiveAvatarSection.avatarImageStyle}
        image={profileData?.profile_image?.full_url || undefined}
        placeHolder={
          <Icon.User
            size={responsiveStyles.responsiveAvatarSection.avatarPlaceholderStyle.size}
            color={GlobalStyles.SLATE_200}
            strokeWidth={1}
          />
        }
      />
      <CardV2 className="widget-content" style={{ flexDirection: 'row', alignSelf: 'stretch', alignItems: 'center' }}>
        <StackV2 direction={'row'} alignItems={'center'}>
          <StackV2 spacing={2} flex={1} direction={'row'} alignItems={'center'}>
            <Avatar
              style={{
                alignItems: 'center',
                height: AppStyles.AVATAR_SIZES.DASHBOARD,
                width: AppStyles.AVATAR_SIZES.DASHBOARD,
                borderRadius: AppStyles.AVATAR_SIZES.DASHBOARD / 2,
              }}
              image={profileData?.profile_image?.full_url || undefined}
              placeHolder={<Icon.User size={AppStyles.AVATAR_SIZES.DEFAULT} color={GlobalStyles.SLATE_200} strokeWidth={1} />}
            />
            {selectedRole && (
              <StackV2>
                <Text size={'sm'}>View by Role:</Text>
                <Text style={{ color: GlobalStyles.MONO_BLACK, fontWeight: '500' }} size={'base'}>
                  {selectedRole.label}
                </Text>
              </StackV2>
            )}
          </StackV2>
          <DropdownV2 menuIcon={<Icon.ChevronDown color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />}>
            {profileData?.roles.map((role) => (
              <DropDownItemV2 key={role.eid} onClick={() => _handleSelect(role)}>
                {role.label}
              </DropDownItemV2>
            ))}
          </DropdownV2>
        </StackV2>
      </CardV2>
    </StackV2>
  )
}

export { UserProfileWidget }
