import { GlobalStyles, Icon } from '@andromeda'
import { Button, Stack, Skeleton as MUISkeleton } from '@mui/material'
import { Orbit, Types } from '@orbit'
import { WidgetHeader } from './widget-header'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { markReadAllUserNotification, markReadUserNotification } from '@redux/reducers/notificationsReducer'
import { useAppDispatch } from '@redux/hooks'
import { useRouter } from 'next/router'
import { iWidget } from '@utils/types/dasboard'
import { listWidgetData } from '@redux/reducers/dashboardReducer'
import { useEffect, useState } from 'react'
import { Notifications } from '../Notifications'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'

export type tNotificationWidget = {
  notifications: Types.Notification.iNotification[] | null
  loading: boolean
  meta?: iWidget
  role?: Types.Roles.iRole
}
export const NotificationsWidget = ({ notifications: notificationsProp, loading, meta: metaProp, role }: tNotificationWidget) => {
  const router = useRouter()

  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const [notifications, setNotifications] = useState(notificationsProp)
  const [meta, setMeta] = useState<tNotificationWidget['meta']>(metaProp)

  const { data, fetchNextPage } = useInfiniteQuery({
    queryKey: ['notifications-widget', role?.eid, metaProp?.eid],
    enabled: !!role && !!metaProp,
    queryFn: async ({ pageParam }) => {
      if (!role || !metaProp) return
      const res = await Orbit.Services.dashboardService.fetchWidgetData({
        role: role?.eid,
        widget: metaProp?.eid,
        page: pageParam.toString(),
      })
      return res.data as unknown as Types.Notification.iNotification[]
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined
      }
      return lastPageParam + 1
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined
      }
      return firstPageParam - 1
    },
  })

  useEffect(() => {
    setNotifications(notificationsProp)
  }, [notificationsProp])

  useEffect(() => {
    setMeta(metaProp)
  }, [metaProp])

  if (loading) {
    return (
      <Stack>
        <SkeletonTheme height={30} baseColor={GlobalStyles.SLATE_100} highlightColor={GlobalStyles.SLATE_200}>
          <Skeleton style={{ width: 100 }} count={1} />
          <Skeleton count={6} />
        </SkeletonTheme>
      </Stack>
    )
  }

  const onClickMarkAllRead = () =>
    dispatch(markReadAllUserNotification()).then(() => {
      if (role && meta)
        dispatch(
          listWidgetData({
            role: role.eid,
            widget: meta.eid,
          })
        ).then((res) => {
          setMeta((prev) => ({ ...prev, ...res.payload.meta }))
          setNotifications(res.payload.data)

          queryClient.invalidateQueries({ queryKey: ['notifications-widget'] })
        })
    })

  return (
    <Stack>
      <WidgetHeader
        title="Latest Notifications"
        IconComponent={(props) => <Icon.Bell {...props} />}
        headerButtons={
          <Button variant="text" startIcon={<Icon.Check />} sx={{ padding: 0 }} onClick={onClickMarkAllRead}>
            Mark All as Read
          </Button>
        }
      />
      <Notifications context="dashboard" notificationsProp={data} fetchNextPageProp={fetchNextPage} />
    </Stack>
  )
}
