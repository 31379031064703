import { GlobalStyles, StackV2, Text, View } from '@andromeda'
import { Stack, Typography } from '@mui/material'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const WidgetHeader = ({
  IconComponent,
  title,
  headerButtons,
  loading = false,
  filter,
}: {
  title: string
  IconComponent?: (props: any) => JSX.Element
  headerButtons?: JSX.Element
  loading?: boolean
  filter?: JSX.Element
}) => {
  return (
    <StackV2 direction={'column'} height={'auto'}>
      <StackV2 direction={'row'} style={{ flexWrap: 'wrap' }} justifyContent={'space-between'} alignItems={'center'} paddingX="12px">
        <StackV2 direction={'row'} style={{ minHeight: 60 }} alignItems={'center'}>
          {loading ? (
            <View style={{ flex: 1 }}>
              <SkeletonTheme height={30} baseColor={GlobalStyles.SLATE_100} highlightColor={GlobalStyles.SLATE_200}>
                <Skeleton count={0.5} />
              </SkeletonTheme>
            </View>
          ) : (
            <Stack direction="row" columnGap="10px" alignItems="center">
              {IconComponent && <IconComponent size={24} color={GlobalStyles.SLATE_500} />}
              <Typography fontSize="24px" color={GlobalStyles.MONO_BLACK} fontWeight="500">
                {title}
              </Typography>
            </Stack>
          )}
        </StackV2>
        {loading ? (
          <View style={{ flex: 1, flexDirection: 'row-reverse' }}>
            <SkeletonTheme height={30} baseColor={GlobalStyles.SLATE_100} highlightColor={GlobalStyles.SLATE_200}>
              <Skeleton width={100} />
            </SkeletonTheme>
          </View>
        ) : (
          headerButtons
        )}
      </StackV2>
      {filter ? filter : <></>}
    </StackV2>
  )
}

export { WidgetHeader }
