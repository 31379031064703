import { Types } from '@orbit'

export type StatusTypes = 'idle' | 'loading' | 'failed' | 'success'

export type JobStatusTypes = Types.Job.StatusTypes

export enum JobStatus {
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
  BOOKED = 'BOOKED',
  COMPLETED = 'COMPLETED',
  WITHDRAWN = 'WITHDRAWN',
  CANCELLED = 'CANCELLED',
  ARCHIVED = 'ARCHIVED',
}
export interface JobProps extends Omit<Types.Job.iJobList, 'status'> {
  status: {
    type: JobStatusTypes
    text: string
  }
  deliverables?: Types.Deliverable.iDeliverable[] | [] | null
  talents?: Array<{ name: string; eid: string; profile_image: string }>
}

export interface iJobDetailsErrorMessages {
  client?: string
  department?: string
  description?: string
  event_type_eids?: string
  location?: string
  name?: string
  number_of_talents_required?: string
  organization?: string
  start_datetime?: string
  email?: string
  phone_number?: string
}

export interface iJobExternal extends Omit<Types.Job.iJobExternal, 'department'> {
  client_name: string
  client_email: string
  client_phone_number?: string | null
  client_organization: string
  department: string | null
}

export interface iJobTab {
  key: string
  label: string
  count: number
}

export interface iJobInitialState {
  status: StatusTypes
  allJobs: JobProps[] | undefined
  latestJobs: JobProps[] | undefined
  filteredJobs: JobProps[] | undefined
  selectedJobListTab: string
  viewedJob: Types.Job.iJob
  viewedJobUsers: Types.Job.iJobUsersResponse | null
  viewedDeliverableUsers: Types.Job.iDeliverableUsersResponse | null
  jobsListMeta: Types.Job.iJobListMeta | null
  viewedJobStatus: StatusTypes
  viewedDeliverableStatus: StatusTypes
  updateJobStatus: StatusTypes
  deleteJobStatus: StatusTypes
  createJobStatus: StatusTypes
  syncJobUsersStatus: StatusTypes
  attachJobFileStatus: StatusTypes
  events: Types.Events.iEventTypes[] | null
  createJobPayload: Types.Job.iJob
  aggregateBucket: any[]
  error: string | null
  assignTalentStatus: string
  errorMessages: iJobDetailsErrorMessages | null
  completeJobStatus: StatusTypes
  createJobRequestPayload: iJobExternal
  createJobRequestStatus: StatusTypes
  getJobRequestStatus: StatusTypes
  shortlistStatus: StatusTypes
  publishJobStatus: StatusTypes
  tabs: Array<iJobTab>
  syncDeliverableUsersStatus: StatusTypes
  viewedDeliverableUsersStatus: StatusTypes
}

export interface iJobCompleteModalProps {
  onConfirm?: () => void
  isLoading?: boolean
}
