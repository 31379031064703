import { GlobalStyles, Icon, StackV2 } from '@andromeda'
import { BannerSettings, Container, Main } from '@components'
import { Box, Divider, Grid, Stack } from '@mui/material'
import { Types } from '@orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { listWidgetData, listWidgets } from '@redux/reducers/dashboardReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { NavigationContext } from '@utils/context/Navigation.context'
import { useEventTypesQuery } from '@utils/query/useEventTypes'
import { useMe } from '@utils/query/useMe'
import {
  WidgetEnums,
  iWidget,
  iWidgetCalendar,
  iWidgetDeliverableOverview,
  // iWidgetJobComplete,
  // iWidgetJobJobs,
  // iWidgetJobPipeline,
  // iWidgetReminder,
  // iWidgetUserProfile,
} from '@utils/types/dasboard'
import moment from 'moment'
import { useRouter } from 'next/router'
import React, { ReactElement, useEffect, useState } from 'react'
import { getFinancialYearDiff } from 'src/andromeda/Widgets/DeliverableOverview/constants'
import {
  Calendar,
  DeliverableOverview,
  JobProgress,
  // JobsOverview,
  // UserProfileWidget
} from 'src/components/dashboard'
import { NotificationsWidget, tNotificationWidget } from 'src/components/dashboard/notifications'

const ROLE_PRIORITY_ORDER = ['account_owner', 'admin', 'talent_manager', 'requester', 'guest']
const getPriorityRole = (roles: Array<Types.User.iUserRole>): Types.Roles.iRole => {
  let role: Types.User.iUserRole | undefined = undefined

  ROLE_PRIORITY_ORDER.forEach((rolePriorityString) => {
    roles.forEach((userRole) => {
      if (!role && userRole.name === rolePriorityString) {
        role = userRole
      }
    })
  })

  return role ?? (roles[0] as Types.Roles.iRole)
}
export default function Workspace<NextPage>() {
  const dispatch = useAppDispatch() // For dispatching Actions
  const { features } = React.useContext(FeatureFlagsContext)
  const { goToRequests } = React.useContext(NavigationContext)

  const { workspaceList } = useAppSelector(selectWorkspace) // Get data from the selector
  const { query, isReady, replace } = useRouter()
  const { workspace } = query
  const isWorkspaceExist = workspaceList?.find((space) => space.slug === workspace)

  const [selectedRole, setSelectedRole] = useState<Types.Roles.iRole | undefined>(undefined)

  // const [availableWidgets, setAvailableWidgets] = useState<Array<iWidget> | undefined>(undefined)

  /**
   * Widget Loading states
   */
  const [widgetListLoading, setWidgetListLoading] = useState<boolean>(false)
  // const [profileLoading, setProfileLoading] = useState<boolean>(true)
  // const [activitiesLoading, setActivitiesLoading] = useState<boolean>(false)
  // const [remindersLoading, setRemindersLoading] = useState<boolean>(false)
  // const [pipelineLoading, setPipelineLoading] = useState<boolean>(false)
  // const [jobsLoading, setJobsLoading] = useState<boolean>(false)
  // const [completedLoading, setCompletedLoading] = useState<boolean>(false)
  const [calendarLoading, setCalendarLoading] = useState<boolean>(false)
  const [deliverableOverviewLoading, setDeliverableOverviewLoading] = useState<boolean>(false)
  const [jobProgressLoading, setJobProgressLoading] = useState<boolean>(false)
  const [notificationsLoading, setNotificationsLoading] = useState<boolean>(false)
  /**
   * Widget Data states
   */
  // const [remindersData, setRemindersData] = useState<Array<iWidgetReminder>>([])
  // const [activitiesData, setActivitiesData] = useState<Array<Types.Audits.iAudit>>([])
  // const [profileData, setProfileData] = useState<iWidgetUserProfile | undefined>(undefined)
  // const [pipelineData, setPipelineData] = useState<iWidgetJobPipeline | undefined>(undefined)
  // const [jobsData, setJobsData] = useState<iWidgetJobJobs | undefined>(undefined)
  // const [completedData, setCompletedData] = useState<iWidgetJobComplete | undefined>(undefined)
  const [deliverableOverviewData, setDeliverableOverviewData] = useState<iWidgetDeliverableOverview | undefined>(undefined)
  const [calendarData, setCalendarData] = useState<iWidgetCalendar | undefined>(undefined)
  const [jobProgressData, setJobProgressData] = useState<Array<Types.Job.iJobList> | undefined>(undefined)
  const [notificationsData, setNotificationsData] = useState<Types.Notification.iNotification[] | null>(null)

  /**
   * Filters
   */
  const [deliverableOverviewWidget, setdeliverableOverviewWidget] = useState<iWidget | undefined>()
  const [calendarWidget, setCalendarWidget] = useState<iWidget | undefined>()
  const [jobProgressWidget, setJobProgressWidget] = useState<iWidget | undefined>()
  const [notificationsWidget, setNotificationsWidget] = useState<iWidget | undefined>()

  const { data: profile } = useMe()
  useEventTypesQuery()

  const fetchWidgetData = (role: Types.Roles.iRole, widgetsList: Array<iWidget> | undefined) => {
    const { eid } = role

    if (widgetsList && widgetsList?.length > 0) {
      for (var widget of widgetsList) {
        switch (widget.type) {
          // case WidgetEnums.USER_PROFILE:
          //     dispatch(listWidgetData({ role: eid, widget: widget.eid })).then((res) => {
          //         setProfileLoading(false)
          //         setProfileData(res.payload)
          //     })
          //     break
          // case WidgetEnums.JOBS_OVERVIEW_JOBS:
          //     setJobsLoading(true)
          //     dispatch(listWidgetData({ role: eid, widget: widget.eid })).then((res) => {
          //         setJobsLoading(false)
          //         setJobsData(res.payload)
          //     })
          //     break
          // case WidgetEnums.JOBS_OVERVIEW_PIPELINE:
          //     setPipelineLoading(true)
          //     dispatch(listWidgetData({ role: eid, widget: widget.eid })).then((res) => {
          //         setPipelineLoading(false)
          //         setPipelineData(res.payload)
          //     })
          //     break
          // case WidgetEnums.JOBS_OVERVIEW_COMPLETE:
          //     setCompletedLoading(true)
          //     dispatch(listWidgetData({ role: eid, widget: widget.eid })).then((res) => {
          //         setCompletedLoading(false)
          //         setCompletedData(res.payload)
          //     })
          //     break
          // case WidgetEnums.USER_TABS_REMINDERS:
          //     setRemindersLoading(true)
          //     dispatch(listWidgetData({ role: eid, widget: widget.eid })).then((res) => {
          //         setRemindersLoading(false)
          //         setRemindersData(res.payload)
          //     })
          //     break
          // case WidgetEnums.USER_TABS_ACTIVITY:
          //     setActivitiesLoading(true)
          //     dispatch(listWidgetData({ role: eid, widget: widget.eid })).then((res) => {
          //         setActivitiesLoading(false)
          //         setActivitiesData(res.payload)
          //     })
          //     break
          case WidgetEnums.DELIVERABLE_OVERVIEW:
            setdeliverableOverviewWidget(widget)
            if (!deliverableOverviewLoading) {
              setDeliverableOverviewLoading(true)
              dispatch(
                listWidgetData({
                  role: eid,
                  widget: widget.eid,
                  from_datetime: moment(new Date(Date.now()))
                    // Last 1 month
                    .subtract(getFinancialYearDiff(), 'M')
                    .format('YYYY-MM-DD'),
                })
              ).then((res) => {
                setDeliverableOverviewLoading(false)
                setDeliverableOverviewData(res.payload.data)
              })
            }
            break
          case WidgetEnums.CALENDAR:
            setCalendarWidget(widget)
            if (!calendarLoading) {
              setCalendarLoading(true)
              dispatch(
                listWidgetData({
                  role: eid,
                  widget: widget.eid,
                })
              ).then((res) => {
                setCalendarLoading(false)
                if (res.meta.requestStatus === 'fulfilled') {
                  setCalendarData(res.payload.data)
                }
              })
            }
            break
          case WidgetEnums.JOB_PROGRESS:
            setJobProgressWidget(widget)
            if (!jobProgressLoading) {
              setJobProgressLoading(true)
              dispatch(
                listWidgetData({
                  role: eid,
                  widget: widget.eid,
                })
              ).then((res) => {
                setJobProgressLoading(false)
                setJobProgressData(res.payload.data)
              })
            }
            break

          case WidgetEnums.NOTIFICATIONS:
            setNotificationsWidget(widget)
            break
          default:
            // console.log('Widgets loaded.')
            // console.log(widget.type)
            break
        }
      }
    } else {
      setDeliverableOverviewData(undefined)
      setCalendarData(undefined)
      setJobProgressData(undefined)
    }
  }

  useEffect(() => {
    // setWidgetListLoading(true)
    if (!isWorkspaceExist) {
      replace('/404')
    }

    if (!features.isDashboardEnabled) {
      goToRequests()
    }

    /**
     * If there's not default role selected, select the role from /me endpoint
     */
    if (isReady && profile?.roles) {
      const role = getPriorityRole(profile.roles)
      setSelectedRole(role as Types.Roles.iRole)

      setWidgetListLoading(true)
      if (!widgetListLoading) {
        dispatch(listWidgets({ role: role.eid })).then((res) => {
          setWidgetListLoading(false)

          const role = getPriorityRole(profile.roles)

          const reducedWidgets: Array<iWidget> = [res.payload ?? []].flat(3) || []
          const reducedWidgetsUnique = reducedWidgets.filter((value, index, self) => self.findIndex((m) => m.type === value.type) === index)

          fetchWidgetData(role, reducedWidgetsUnique)
        })
      }
    }
  }, [workspace, profile, isReady])

  // useEffect(() => {
  //     dispatch(me())
  // }, [])

  return (
    <Main paddingTop={0}>
      <StackV2 rowGap={{ xxs: '16px', md: '24px' }}>
        {/* <StackV2 spacing={2} direction={{ md: 'column', lg: 'row', xl: 'row' }} justifyContent={'center'}>
                    <UserProfileWidget
                        profileData={profileData}
                        widgets={availableWidgets}
                        profile={profile}
                        loading={profileLoading || widgetListLoading}
                        initialRole={selectedRole}
                        onChangeRole={(role) => fetchWidgetData(role, availableWidgets)}
                    />
                </StackV2> */}
        {/* <JobsOverview
                    completedData={completedData}
                    jobsData={jobsData}
                    pipelineData={pipelineData}
                    widgets={availableWidgets}
                    pipelineLoading={pipelineLoading || widgetListLoading}
                    jobsLoading={jobsLoading || widgetListLoading}
                    completedLoading={completedLoading || widgetListLoading}
                /> */}
        <Stack columnGap="48px" direction={{ xxs: 'column', md: 'row' }}>
          <Stack flex={{ xxs: 1, md: 0.6 }}>
            <JobProgress
              jobProgressData={jobProgressData}
              jobProgressLoading={!jobProgressData && (jobProgressLoading || widgetListLoading)}
              meta={jobProgressWidget}
              role={selectedRole}
            />
          </Stack>
          <Stack flex={{ xxs: 1, md: 0.4 }}>
            <NotificationsWidget
              notifications={notificationsData}
              loading={notificationsLoading || widgetListLoading}
              meta={notificationsWidget}
              role={selectedRole}
            />
          </Stack>
        </Stack>
        <Divider sx={{ display: { xxs: 'none', sm: 'initial' } }} />
        <Box flexGrow={0}>
          <Calendar
            loading={!calendarData && (calendarLoading || widgetListLoading)}
            calendarData={calendarData}
            meta={calendarWidget}
            role={selectedRole}
          />
        </Box>
        <StackV2>
          <DeliverableOverview
            deliverableOverviewData={deliverableOverviewData}
            loading={!deliverableOverviewData && (deliverableOverviewLoading || widgetListLoading)}
            meta={deliverableOverviewWidget}
            role={selectedRole}
          />
        </StackV2>
      </StackV2>
    </Main>
  )
}

Workspace.getLayout = function getLayout(page: ReactElement) {
  return <Container banner={<BannerSettings IconComponent={(props) => <Icon.Home {...props} />} useWorkspaceAsTitle />}>{page}</Container>
}
