import { Orbit, Types } from '@orbit'
import { StatusTypes } from './jobs'

export enum WidgetEnums {
  USER_PROFILE = 'user-profile',
  JOBS_OVERVIEW = 'jobs-overview',
  JOBS_OVERVIEW_JOBS = 'jobs-overview-jobs',
  JOBS_OVERVIEW_PIPELINE = 'jobs-overview-pipeline',
  JOBS_OVERVIEW_COMPLETE = 'jobs-overview-complete',
  USER_TABS = 'user-tabs',
  USER_TABS_REMINDERS = 'user-tabs-reminders',
  USER_TABS_ACTIVITY = 'user-tabs-activity',
  DELIVERABLE_OVERVIEW = 'deliverable-overview',
  CALENDAR = 'calendar',
  JOB_PROGRESS = 'job-progress',
  NOTIFICATIONS = 'user-workspace-notifications',
}

export type iWidget = { eid: string; type: string }
export type iWidgetList = Array<{ eid: string; type: string; children?: Array<iWidget> }> | null

export type iWidgetUserProfile = {
  eid: string
  name: string
  email: string
  roles: Array<Types.Roles.iRole>
  profile_image: Types.User.iProfileImage
}

export type iWidgetReminder = {
  eid: string
  event: string
  reminder: string
  days: string
  status: StatusTypes | undefined
}

export type iWidgetJobPipeline = {
  pipeline_count: number
  external_leads_count: number
  drafts_count: number
  pipeline_value: number
  pipeline_value_currency: string
}

export type iWidgetJobJobs = {
  job_count: number
  assigned_deliverables: number
  booked_deliverables: number
  job_value: number
  job_value_currency: string
}

export type iWidgetJobComplete = {
  completed_jobs_count: number
  completed_deliverables_count: number
  completed_jobs_value: number
  job_value_currency: string
}

export type iWidgetDeliverableOverview = {
  deliverables_total_count: number
  deliverables_per_type: Array<{
    type: string
    count: number
  }>
}

export type iWidgetCalendar = Array<{
  eid: string
  name: string
  description: string
  job_status: string
  job_eid: string
  status: string
  start_datetime: string
  end_datetime: string
}>
